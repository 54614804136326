import "../styles/Home.css"
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ReactComponent as Brand } from "../images/logo/logo.svg";
const Home = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs
        .sendForm(
          "service_f58cltf",
          "template_fwbyv0m",
          form.current,
          "laTB_7_rcMdeKJMlW"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

    return (
      <div>
        <section className="main" id="main">
          <div className="intro">
            <h1>SCHULTZ KRUPP PARTNERSHIP</h1>
            <h2>Design And Build</h2>
            <p>
              A multi-<span>disciplinary partnership established in </span>2005
              with the objective of del
              <span>
                ivering sustainable, aesthetically pleasing modern solutions to
                clients’ needs
              </span>
            </p>
            <a href="#" className="learn_more">
              Get In Touch
            </a>
          </div>
        </section>
        <section className="about" id="about">
          <div className="about_text">
            <h1>Who we are</h1>
            <p>
              SKP was set up to bring innovation and consistency into the luxury
              real estate market. With a combined experience of over 30 years,
              we have provided consultancy services to a varied clientele
              ranging from small-scale to mega-sized residential and commercial
              projects. Our team of Architects, Planners, Project managers, and
              Urban designers is committed to the highest level of
              professionalism. Well-equipped to bring your ideas into reality.
            </p>
          </div>
          <div className="spacer"></div>
        </section>
        <section className="contact_form" id="contact_form">
          <form ref={form} onSubmit={sendEmail}>
            <div className="contact_left">
              <h1 className="c_l_heading">
                Write<span> Us</span>
              </h1>
              <div className="f_name">
                <span>Name</span>
                <input
                  type="text"
                  placeholder="Full Name"
                  name="user_name"
                ></input>
              </div>
              <div className="f_email">
                <span>Email</span>
                <input type="email" 
                placeholder="example@email.com" 
                name="user_email"></input>
              </div>
            </div>
            <div className="contact_right">
              <div className="message">
                <span>Message</span>
                <textarea
                  name="message"
                  rows="5"
                  cols="20"
                  placeholder="Write Message"
                ></textarea>
                <button>submit</button>
              </div>
            </div>
          </form>
        </section>
        <section className="contact_us" id="contact_us">
          <div className="c_info">
            <h1>Contact Us</h1>
            <p>
              <span>Nigeria: </span>212B, Isale Eko Avenue, Dolphin Estate,
              Ikoyi. Lagos State, Nigeria
            </p>
            <p>
              <span>Contact no: </span> +234 8036306958, +2348025145115
            </p>
            <p>
              <span>Email: </span>info@askparchitects.com
            </p>
          </div>
          <div className="google_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.4896809705087!2d3.416507974114765!3d6.459472023905907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b3537cbf40b%3A0x84d03e388ebcc7cf!2s212b%20Isale%20Eko%20Ave%2C%20Dolphin%20Estate%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1684319191439!5m2!1sen!2sng"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
        <footer></footer>
      </div>
    );
}

export default Home;