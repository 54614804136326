import { ReactComponent as Brand } from "../images/logo/logo.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import "../styles/Navbar.css"


const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">SCHULTZ KRUPP PARTNERSHIP</div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <a href="#main">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#">Services</a>
            </li>
            <li>
              <a href="#contact_form">Enquire</a>
            </li>
            <li>
              <a href="#contact_us" className="contact">
                Contact us
              </a>
            </li>
          </ul>
        </div>
        <div></div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <FaBars />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
